<template>
    <privateProfileLayout>
        <div class="private_profile" data-page="private-profile" :data-edit-mode="viewContent === 'edit-profile'">
            <div class="content-page">
                <!-- Skeleton Loader -->
                <div class="head_profile" v-if="loader === true">
                    <div class="profile-header-container">
                        <headSkeletonLoader :parent="'private-profile'"></headSkeletonLoader>
                    </div>
                </div>

                <div class="head_profile" :class="viewContent" v-if="loader === false">
                    <div class="profile-header-container">
                        <div class="profile_head_background" 
                             :class="{'default': isImageDefault}"
                             :style="!isImageDefault ? {
                                 backgroundImage: `url('${smallImageUrl}')`,
                                 backgroundSize: 'cover',
                                 backgroundPosition: 'center'
                             } : null">
                        </div>
                        <privateProfileAvatar @avatar-updated="refreshBackground"></privateProfileAvatar>
                        <div class="user_info">
                            <!-- VIEW PROFILE -->
                            <div class="user_name" :class="{'mobile': mobile}">
                                <div class="name-badge-wrapper">
                                    {{ USER.fullName }}
                                    <template v-if="PROFILE">
                                        <v-tooltip top close-delay="0" transition="false">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="profile_badge" v-bind="attrs" v-on="on"
                                                     :class="{
                                                         'premium': PROFILE.subscriptionPlan === 'premium', 
                                                         'founder': PROFILE.isFounder === true
                                                     }">
                                                </div>
                                            </template>
                                            <div v-if="PROFILE.subscriptionPlan === 'premium' && PROFILE.isFounder === false">Premium account</div>
                                            <div v-if="PROFILE.subscriptionPlan === 'premium' && PROFILE.isFounder === true">Founder's account</div>
                                        </v-tooltip>
                                    </template>
                                </div>
                            </div>

                            <!-- EDIT-PROFILE -->
                            <template v-if="viewContent === 'edit-profile' && tabs === 2">
                                <div class="input-style-4">
                                    <label for="full-name">Full Name</label>
                                    <input type="text" id="full-name" required v-model="changeData.fullName">
                                </div>
                                <div class="input-style-4">
                                    <label for="address">Location</label>

                                    <vue-google-autocomplete
                                            v-model="changeData.address"
                                            id="map"
                                            classname="form-control"
                                            placeholder="City, state"
                                            types="(cities)"
                                            v-on:keyup="keypressLocation"
                                            v-on:placechanged="getAddressData">
                                    </vue-google-autocomplete>
                                </div>

                                <div class="about">
<!--                                    <label for="">Services</label>-->
<!--                                    <div class="wrap_checkbox_roles">-->
<!--                                        <div class="material_checkbox">-->
<!--                                            <input type="checkbox" id="dj" value="Dj" v-model="userRoles">-->
<!--                                            <label for="dj">Dj</label>-->
<!--                                        </div>-->
<!--                                        <div class="material_checkbox">-->
<!--                                            <input type="checkbox" id="producer" value="Producer" v-model="userRoles">-->
<!--                                            <label for="producer">Producer</label>-->
<!--                                        </div>-->
<!--                                        <div class="material_checkbox">-->
<!--                                            <input type="checkbox" id="promoter" value="Promoter" v-model="userRoles">-->
<!--                                            <label for="promoter">Promoter</label>-->
<!--                                        </div>-->
<!--                                        <div class="material_checkbox">-->
<!--                                            <input type="checkbox" id="vendor" value="Vendor" v-model="userRoles">-->
<!--                                            <label for="vendor">Vendor</label>-->
<!--                                        </div>-->
<!--                                        <div class="material_checkbox">-->
<!--                                            <input type="checkbox" id="deco-artist" value="Deco Artist" v-model="userRoles">-->
<!--                                            <label for="deco-artist">Deco Artist</label>-->
<!--                                        </div>-->
<!--                                    </div>-->
                                </div>
                            </template>
                            <template v-else>
                                <div class="user_location" v-if="USER.address">
                                    {{ USER.address }}
                                </div>
                                <div class="follows">
                                    <div class="followers">
                                        <span class="count">{{ USER.followersCount }}</span>
                                        <router-link :to="{name: 'manageFollowers'}">Followers</router-link>
                                    </div>
                                    <div class="following">
                                        <span class="count">{{ USER.followingCount }}</span>
                                        <router-link :to="{name: 'following'}">Following</router-link>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="wrap_profile_music_events">
                    <div class="head_profile_music_events">
                        <v-container>
                            <v-tabs
                                    background-color="transparent"
                                    color="#fff"
                                    v-model="tabs">
                                <v-tab v-for="tab in tabsSections" :key="tab.index">
                                    {{ tab.name }}
                                </v-tab>
                            </v-tabs>
                            <div class="actions">
                                <button v-if="viewContent !== 'edit-profile'" type="button" class="edit_profile" @click="editProfile" v-ripple>Edit profile
                                </button>
                                <!-- more button -->
                                <v-menu
                                        transition="slide-y-transition"
                                        :close-on-content-click="true"
                                        offset-y
                                        nudge-left="21"
                                        nudge-bottom="10"
                                        origin="top center"
                                        content-class="menu-pointer charcoal">

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon color="#7481A6" class="more" v-bind="attrs" v-on="on">
                                            <span class="material-icons">more_vert</span>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <!-- popup share link -->
                                        <v-list-item>
                                            <v-dialog
                                                    transition="dialog-bottom-transition"
                                                    max-width="600">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <button type="button" v-bind="attrs" v-on="on">Share</button>
                                                </template>
                                                <template v-slot:default="dialog">
                                                    <div class="content_popup_share">
                                                        <div class="head_popup">Share</div>
                                                        <div class="input_box">
                                                            <input type="text" id="input-share-link"
                                                                   v-model="shareLink">
                                                            <button type="button" @click="copyUserLink">Copy</button>
                                                        </div>
                                                        <button type="button" @click="dialog.value = false"
                                                                class="close">Close
                                                        </button>
                                                    </div>
                                                </template>
                                            </v-dialog>
                                        </v-list-item>
                                        <!-- Report -->
                                        <v-list-item>
                                            <button type="button" class="report">Report</button>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </v-container>
                    </div>
                    <div class="content_profile_music_events">
                        <v-tabs-items touchless v-model="tabs">
                            <v-tab-item> <!-- MUSIC SECTION -->
                                <div class="music_section">
                                    <!-- ************** RECENTLY PLAYED ALBUMS ************** -->
                                    <div class="recently_played" :class="{'empty': !recentlyPlayedLoader && !PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS.length}">
                                        <v-container>
                                            <ContentHeader>
                                                <template #title>Recently played</template>
                                                <template #action>
                                                    <v-btn outlined @click="showComingSoonDialog = true">History</v-btn>
                                                </template>
                                            </ContentHeader>

                                            <div class="albums_row">
                                                <template v-if="recentlyPlayedLoader">
                                                    <albumGridSkeletonLoader v-for="index in 6" :key="index"
                                                                             :parent="'default_grid_albums'"></albumGridSkeletonLoader>
                                                </template>
                                                <template v-if="!recentlyPlayedLoader && PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS.length">
                                                    <albumGrid v-for="album in PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS.slice(0, 6)"
                                                               :key="album.id"
                                                               :album="album"
                                                               :playZone="PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS">
                                                    </albumGrid>
                                                </template>
                                            </div>
	                                        <nothingHereYet v-if="!recentlyPlayedLoader && !PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS.length"></nothingHereYet >
                                        </v-container>
                                    </div>

                                    <!-- ************** RECENTLY LIKED ALBUMS ************** -->
                                    <div class="recently_liked" :class="{'empty': !recentlyLikedLoader && !PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS.length}">
                                        <v-container>
                                            <ContentHeader>
                                                <template #title>Recently liked</template>
                                                <template #action>
                                                    <v-btn outlined to="/library">Library</v-btn>
                                                </template>
                                            </ContentHeader>
                                            <div class="albums_row">
                                                <template v-if="recentlyLikedLoader">
                                                    <albumGridSkeletonLoader v-for="index in 6" :key="index"
                                                                             :parent="'default_grid_albums'"></albumGridSkeletonLoader>
                                                </template>
                                                <template v-if="!recentlyLikedLoader && PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS.length">
                                                    <albumGrid v-for="album in PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS.slice(0, 6)"
                                                               :key="album.id"
                                                               :album="album"
                                                               :small="true"
                                                               :playZone="PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS">
                                                    </albumGrid>
                                                </template>
                                            </div>
	                                        <nothingHereYet v-if="!recentlyLikedLoader && !PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS.length"></nothingHereYet>
                                        </v-container>
                                    </div>
                                </div>
                            </v-tab-item>
                            <v-tab-item> <!-- EVENTS SECTION -->
                                <div class="events_section">
                                    <div class="uploaded_events">
                                        <v-container>
                                            <ContentHeader>
                                                <template #title>Recently viewed</template>
                                            </ContentHeader>
                                            <nothingHereYet></nothingHereYet>
                                        </v-container>
                                    </div>
                                </div>
                            </v-tab-item>
                            <v-tab-item> <!-- ABOUT SECTION -->
                                <div class="about_section" :data-edit-mode="viewContent === 'edit-profile'">
                                    <v-container>
                                        <!-- New Info Section -->
                                        <ContentHeader v-if="viewContent !== 'edit-profile'">
                                            <template #title>Info</template>
                                        </ContentHeader>
                                        <div class="wrap_about_section">
                                            <template v-if="YOUR_ARTISTS && YOUR_ARTISTS.length">
                                                <div class="artist_projects">
                                                    <div class="label_">Projects:</div>
                                                    <div class="list_artists">
                                                        <div class="artists" v-for="artist in YOUR_ARTISTS" :key="artist.id">
                                                            <div @click="redirectToArtistPage(artist.id, artist.identifier)">
                                                                {{ artist.name.trim() }}
                                                            </div>
                                                            <span v-if="YOUR_ARTISTS && YOUR_ARTISTS.length > 1">,</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <nothingHereYet></nothingHereYet>
                                            </template>
                                        </div>

                                        <!-- Existing Bio Section -->
                                        <ContentHeader v-if="viewContent !== 'edit-profile'">
                                            <template #title>Bio</template>
                                        </ContentHeader>
                                        <div class="wrap_about_section">
                                            <template v-if="viewContent === 'edit-profile'">
                                                <TextareaAutosize v-model="changeData.description"></TextareaAutosize>
                                                <div class="wrap_action_btn">
                                                    <button type="button" class="save" v-ripple @click="saveUserData">Save</button>
                                                    <button type="button" class="close" v-ripple @click="cancel">Cancel</button>
                                                </div>
                                            </template>

                                            <template v-else>
                                                <template v-if="USER.description">
                                                    <div v-html="formattedBio"></div>
                                                </template>
                                                <template v-else>
	                                                <nothingHereYet></nothingHereYet>
                                                </template>
                                            </template>
                                        </div>
                                    </v-container>
                                </div>
                            </v-tab-item>
                        </v-tabs-items>
                    </div>
                </div>
            </div>
            <ComingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog"></ComingSoonDialog>
        </div>
    </privateProfileLayout>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import ContentHeader from '@/components/ContentHeader.vue'
import privateProfileLayout from "@/layouts/profiles/privateProfileLayout.vue";
import headSkeletonLoader from "@/components/small/skeleton-loaders/headSkeletonLoader";
import albumGridSkeletonLoader from "@/components/small/skeleton-loaders/albumGridSkeletonLoader";
import privateProfileAvatar from "@/components/profile/privateProfileAvatar";
import albumGrid from '@/components/musicReleases/albumGrid';

const ComingSoonDialog = () => import("@/components/dialogs/comingSoonDialog");
import isMobile from "@/mixins/isMobile";
import checkAccessToken from "@/mixins/checkAccessToken";
import Defer from "@/utils/defer";
import nothingHereYet from "@/components/small/nothingHereYet";

export default {
    name: "private-profile",
    components: {
        ContentHeader,
        privateProfileLayout,
        privateProfileAvatar,
        albumGrid,
        ComingSoonDialog,
        VueGoogleAutocomplete,
        headSkeletonLoader,
        albumGridSkeletonLoader,
        nothingHereYet
    },
    mixins: [checkAccessToken, isMobile, Defer()],
    data() {
        return {
			changeData: {
				fullName: '',
				address: '',
				description: '',
			},
            showComingSoonDialog: false,
            viewContent: 'my-profile',
            shareLink: '',
            smallImageUrl: '',
            isImageDefault: false,

            tabsSections: [
                {index: 0, name: 'Music'},
                {index: 1, name: 'Events'},
                {index: 2, name: 'About'}
            ],
            tabs: null,

            showDescription: false, // mobile

            loader: false,
            changeDataLoader: false,
            recentlyPlayedLoader: false,
            recentlyLikedLoader: false,
            uploadedAlbumsLoader: false,
            location: '',

            mobile: false,
        }
    },

    watch: {
		'USER.fullName': function (val) {
			if (!this.checkAccessToken()) {
				console.log('watch USER.fullName -redirect login');
				this.$router.push({name: 'login'});
			}
		},
	    'USER.address': function (val) {
		    if (!this.checkAccessToken()) {
			    console.log('watch USER.address -redirect login');
			    this.$router.push({name: 'login'});
		    }
	    },
		'USER.description': function (val) {
			if (!this.checkAccessToken()) {
				console.log('watch USER.description -redirect login');
				this.$router.push({name: 'login'});
			}
		},
        PROFILE: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.$nextTick(() => {
                        this.checkMobile();
                        this.$forceUpdate();
                    });
                }
            }
        }
    },

    computed: {
        ...mapGetters([
            'PROFILE',
            'USER', 'AUTH_DATA', 'userID',
            'YOUR_ARTISTS',
            'PRIVATE_PROFILE_UPLOADED_ALBUMS',
            'PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS',
            'PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS',
            'PROFILE_USER_LOGO',
            'USER',
            'PROFILE_LOGO',
        ]),
        formattedBio() {
            return this.USER.description.replace(/\n/g, '<br>');
        },
        backgroundImageUrl() {
            return this.USER?.imageUrl || this.PROFILE?.imageUrl || null;
        }
    },
    mounted() {
        this.debouncedCheckMobile = this.debounce(this.checkMobile, 250);
        window.addEventListener('resize', this.debouncedCheckMobile);
        
        this.checkMobile();
        this.getData();
        this.getRecentlyPlayed();
        this.getRecentlyLiked();
        
        console.log('Initial PROFILE_LOGO:', this.PROFILE_LOGO);
    },
    methods: {
        ...mapActions([
            'GET_USER', 'CHANGE_USER_DATA', 'GET_YOUR_ARTISTS',
            'GET_RECENTLY_PLAYED',
            'GET_PRIVATE_PROFILE_UPLOADED_ALBUMS',
            'GET_PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS',
            'GET_PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS'
        ]),
	    redirectToArtistPage(artistId, artistName) {
		    this.$router.push({
		        name: 'artist-profile', 
		        params: {
		            id: artistId, 
		            name: window.toHumanFormat(artistName)
		        }
		    });
	    },
		getUserData() {
			this.loader = true;
			this.GET_USER(this.userID)
				.then(() => {
					this.changeData.fullName = this.USER.fullName;
					this.changeData.address = this.USER.address;
					this.changeData.description = this.USER.description;

					if (this.PROFILE_LOGO && typeof this.PROFILE_LOGO === 'object') {
						const logoUrl = this.PROFILE_LOGO['240x270'] || Object.values(this.PROFILE_LOGO)[0];
						if (logoUrl) {
							this.smallImageUrl = logoUrl;
							this.isImageDefault = false;
							console.log('Setting background image to:', this.smallImageUrl);
						} else {
							this.setDefaultImage();
						}
					} else {
						this.setDefaultImage();
					}
				})
				.catch(err => {
					console.log(`private-profile, GET_USER, ${err}`);
				})
				.finally(() => {
					this.loader = false;
				});
		},
        getData() {
            this.loader = true;
            this.uploadedAlbumsLoader = true;
            this.shareLink = `${window.location.hostname}/user/${this.userID}`;

	        this.getUserData();
            this.GET_YOUR_ARTISTS({owner: this.userID})
                .then(() => {
                });
        },

        getRecentlyPlayed() {
            this.recentlyPlayedLoader = true;
            let params = {status: 'published', order: 'desc', limit: 6}
            this.GET_PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS(params)
                .catch(err => console.log(`getRecentlyPlayed, ${err}`))
                .finally(() => {
                    this.recentlyPlayedLoader = false;
                })
        },
        getRecentlyLiked() {
            this.recentlyLikedLoader = true;
            let params = {status: 'published', order: 'desc', limit: 6}
            this.GET_PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS(params)
                .catch(err => console.log(`getRecentlyLiked, ${err}`))
                .finally(() => {
                    this.recentlyLikedLoader = false;
                })
        },

        saveUserData() {
	        this.loader = true;
	        this.viewContent = 'my-profile';
	        this.changeDataLoader = true;

            const userData = {
                id: this.userID,
                fullName: this.changeData.fullName,
                address: this.changeData.address,
                description: this.changeData.description,
            };

            this.CHANGE_USER_DATA(userData)
                .then(() => {
					this.getUserData();
                })
                .catch(err => {
                    console.log(`saveUserData, ${err}`);
                })
                .finally(() => this.changeDataLoader = false);
        },

        keypressLocation(event) {
			console.log('keypressLocation', event.target.value);
			if (event.target.value) {
				this.changeData.address = event.target.value;
			}
        },
        getAddressData: function (addressData, placeResultData) {
			console.log('getAddressData', addressData);
            if (addressData.country === 'United States') {
                this.changeData.address = `${addressData.locality},  ${addressData.administrative_area_level_1}, ${addressData.country}`;
            } else {
                this.changeData.address = `${addressData.locality}, ${addressData.country}`;
            }
        },
        editProfile() {
	        let accessToken = localStorage.getItem('accessToken');
			if (accessToken) {
				this.viewContent = 'edit-profile';
				this.tabs = 2; // aboutSection
			} else {
				console.log('editProfiles -redirect login');
				this.$router.push({name: 'login'});
			}
        },
        cancel() {
            this.viewContent = 'my-profile';
            this.tabs = 0; // musicSection
	        this.changeData.fullName = this.USER.fullName;
	        this.changeData.address = this.USER.address;
			this.changeData.description = this.USER.description;
        },
        copyUserLink() {
            navigator.clipboard.writeText(this.shareLink);
        },
        toggleDescription() {
            this.showDescription = !this.showDescription;
        },
        setDefaultImage() {
            this.smallImageUrl = require('@/assets/image/svg/default_avatar.svg');
            this.isImageDefault = true;
            console.log('Using default image');
        },
        checkMobile() {
            this.mobile = window.innerWidth <= 767;
        },
        debounce(fn, delay) {
            let timeoutId;
            return function (...args) {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => fn.apply(this, args), delay);
            };
        },
        refreshBackground() {
            // Force background update by triggering a new key
            this.smallImageUrl = this.PROFILE_LOGO['240x270'] + '?cache=' + new Date().getTime();
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.debouncedCheckMobile);
    }
}
</script>

<style>
</style>
